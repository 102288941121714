* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html, body {
  width: 100%;
  height: 100vh;
}

#app {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
}

main {
  flex: 1 0 auto;
}

a {
  color: #fff;
  text-decoration: none;
}

.editor {
  margin-top: 2rem;
}

svg {
  pointer-events: none;
}

.MuiIconButton-label {
  pointer-events: none;
}

.MuiButton-label {
  pointer-events: none;
}

input {
  outline: none;
}

.disabled {
  text-decoration: line-through;
  color: rgba(0, 0, 0, 0.26);
}